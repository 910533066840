@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;900&display=swap');


@layer base {
    body {
        font-family: 'Roboto', sans-serif;
        @apply text-black;
        background-color: #f5f5f5;
        overflow-x: hidden;
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }
}

li:hover {
    color: #FF0000;
}


.buttons:hover {
    color: black;
    background-color: whitesmoke;
}

.active {
    color: #FF0000;
};

.card {
    width: 100px;
    height: 300px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .copied-text {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .copied-text.show {
    opacity: 1;
  }

  @media (max-width: 1024px) {
    .stars {
        display: none;
     }
    }
